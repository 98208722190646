export const URLConstants = {
  home: {
    pathname: "/",
  },
  accessDenied: {
    pathname: "/access-denied",
  },
  signin: {
    pathname: "/signin",
  },
  signup: {
    pathname: "/signup",
  },
  group: {
    pathname: "/group",
    create: {
      pathname: "/group/create",
    },
    setting: {
      pathname: "/group/setting",
    },
    edit: {
      pathname: "/group/edit",
    },
    auth: {
      add: {
        pathname: "/group/auth/add",
      }
    },
    mypage: {
      pathname: "/group/mypage",
    },
    members: {
      pathname: "/group/members",
      add: {
        pathname: "/group/members/add",
      },
      delete: {
        pathname: "/group/members/delete",
      }
    },
    addmembers: {
      pathname: "/group/add-members",
    }
  },
  user: {
    following: {
      pathname: "/user/following",
      new_posts: {
        pathname: "/user/following/new-posts",
      },
    },
    follower: {
      pathname: "/user/follower",
    },
    mypage: {
      pathname: "/user/mypage",
    },
    average: {
      pathname: "/user/average",
    },
    scoring: {
      history: {
        pathname: "/profile/scoring/history",
      },
    },
  },
  ranking: {
    pathname: "/ranking",
  },
  image: {
    pathname: "/image",
    latest: {
      pathname: "/image/latest",
    },
    following: {
      pathname: "/image/following",
    },
  },
} as const;

// Laravel用のAPI
export const BackendURLContantas = {
  LOCALE: {
    SET_LOCALE: {
      PATH_NAME: "/set-locale",
    }
  },
  Auth: {
    SINGUP: {
      PATH_NAME: "/signup",
    },
    LOGOUT: {
      PATH_NAME: "/logout",
    },
    LOGIN: {
      PATH_NAME: "/login",
    },
  },
  IMAGE: {
    PATH_NAME: "/image/index",
    SEARCH: {
      PATH_NAME: "/image/search",
    },
    POST: {
      PATH_NAME: "/image/post",
    },
    FOLLOWING: {
      PATH_NAME: "/image/following",
    },
    DELETE: {
      PATH_NAME: "/image/delete",
    },
    SHOW: {
      PATH_NAME: "/image/show",
    },
    FIND: {
      PATH_NAME: "/image/find",
    },
    TOTAL: {
      PATH_NAME: "/image/total",
    },
    AVG: {
      PATH_NAME: "/image/avg",
    },
    LATEST: {
      PATH_NAME: "/image/latest",
    },
    RANKING: {
      HIGHEST: {
        PATH_NAME: "/image/ranking/highest",
      },
      LOWEST: {
        PATH_NAME: "/image/ranking/lowest",
      },
      NOT_SCORED: {
        PATH_NAME: "/image/ranking/not-scored",
      },
      LATEST: {
        PATH_NAME: "/image/ranking/latest",
      },
      FOLLOWING: {
        PATH_NAME: "/image/ranking/following",
      },
    },
    EXCLUDE_FROM_GROUP: {
      PATH_NAME: "/image/group/exclude-from-group",
    },
    SCORE_EXISTS: {
      PATH_NAME: "/image/score-exists",
    }
  },
  USER: {
    PATH_NAME: "/user",
    POST: {
      PATH_NAME: "/user/post",
    },
    UPDATE: {
      HIDE_NAME: {
        PATH_NAME: "/user/update/hide-name",
      },
      PATH_NAME: "/user/update",
    },
    SEARCH: {
      PATH_NAME: "/user/search",
    },
    EXISTS_USER_ID: {
      PATH_NAME: "/user/exists-user-id",
    },
    CHANGE_PASSWORD: {
      PATH_NAME: "/user/change-password",
    },
    GET: {
      PATH_NAME: "/user/get",
    },
    ICON: {
      PATH_NAME: "/user/icon",
      UPDATE: {
        PATH_NAME: "/user/icon-update",
      },
    },
    TOTAL_AND_AVG: {
      PATH_NAME: "/user/total-and-avg",
    },
    TOTAL: {
      PATH_NAME: "/user/total",
    },
    AVG: {
      PATH_NAME: "/user/avg",
    },
    IMAGE: {
      PATH_NAME: "/user/image",
      LATEST: {
        PATH_NAME: "/user/image/latest",
      },
      HIGHEST: {
        PATH_NAME: "/user/image/highest",
      },
      LOWEST: {
        PATH_NAME: "/user/image/lowest",
      },
      NOT_SCORED: {
        PATH_NAME: "/user/image/not-scored",
      },
    },
    HISTORY: {
      PATH_NAME: "/user/history/desc",
    },
    FOLLOWED: {
      PATH_NAME: "/user/followed",
    },
    FOLLOWING: {
      PATH_NAME: "/user/following",
    },
    FOLLOW_USERS: {
      PATH_NAME: "/user/follow-users",
    },
    FOLLOWING_USERS: {
      PATH_NAME: "/user/following-users",
    },
  },
  SCORE: {
    PATH_NAME: "/score",
  },
  GROUP: {
    INDEX: {
      PATH_NAME: "/group/index",
    },
    GET_APPROVAL_PENDING: {
      PATH_NAME: "/group/get-approval-pending",
    },
    PRIVATE: {
      PATH_NAME: "/group/private/index",
    },
    CREATE: {
      PATH_NAME: "/group/create",
    },
    SCORE_INFO: {
      PATH_NAME: "/group/score-info",
    },
    STORE: {
      PATH_NAME: "/group/store",
    },
    UPDATE: {
      PATH_NAME: "/group/update",
    },
    UPDATE_ICON: {
      PATH_NAME: "/group/update-icon",
    },
    EXISTS: {
      PATH_NAME: "/group/exists",
    },
    FIND: {
      PATH_NAME: "/group/find",
    },
    NOTIFY: {
      PATH_NAME: "/group/notify",
    },
    ADD_MEMBERS: {
      PATH_NAME: "/group/add-members",
    },
    DELETE_MEMBERS: {
      PAT_HNAME: "/group/delete-members",
    },
    ADD_AUTH_MEMBERS: {
      PAT_HNAME: "/group/add-auth",
    },
    GET_ADDTIONAL_MEMBERS: {
      PATH_NAME: "/group/get-addtional-members",
    },
    GET_MEMBERS: {
      PATH_NAME: "/group/get-members",
    },
    GET_PENDING_MEMBERS: {
      PATH_NAME: "/group/pending-members",
    },
    MEMBER_ACCEPT: {
      PATH_NAME: "/group/accept",
    },
    EXISTS_JOIN_MEMBER: {
      PATH_NAME: "/group/exists-join-member",
    },
    POST_JOIN_OR_LEAVE: {
      PATH_NAME: "/group/post-join-or-leave",
    },
    REQUEST_JOIN: {
      PATH_NAME: "/group/request-join",
    },
    REQUEST_ADMIN_JOIN: {
      PATH_NAME: "/group/request-admin-join",
    },
    RESPOND: {
      PATH_NAME: "/group/respond",
    },
    APPLICATION_STATUS: {
      PATH_NAME: "/group/application-status",
    },
    PENDING_USERS_COUNT: {
      PATH_NAME: "/group/pending-users-count",
    },
    JOIN_OR_DECLINE: {
      PATH_NAME: "/group/join-or-decline",
    },
    EXITSTS_PENDING_USER: {
      PATH_NAME: "/group/exitsts-pending-user",
    },
    GET_GROUP_WITH_IMAGE: {
      PATH_NAME: "/group/get-group-with-image",
    },
    GET_ALL_GROUPS_IMAGES: {
      PATH_NAME: "/group/get-all-groups-images",
    }
  },
  NOTIFICATION: {
    SCORING_HISTORY: {
      PATH_NAME: "/notify/scoring-history",
      READ: {
        PATH_NAME: "/notify/scoring-history/read",
      },
    },
    NEW_POSTED_IMAGE: {
      PATH_NAME: "/notify/new-posted-image",
      READ: {
        PATH_NAME: "/notify/new-posted-image/read",
      },
      GET: {
        PATH_NAME: "/notify/new-posted-image/get",
      }
    },
  },
} as const;
